<template>
    <div class="intro">

        <div class="wrapper">

            <div class="inner">

                <div class="intro-slide" :class="isActive(index)" v-for="(data,index) in slideData" :key="index">

                    <h5>Willkommen bei</h5>
                    <img class="logo" src="/assets/logo/logo-primary.png" alt="Tripmeister">
                    <p class="desc">{{ data }}</p>

                    <div class="icons">
                        <div class="icon" :class="isActive(0)">
                            <img v-if="index == 0" src="/assets/icons/intro/home-lg.png" alt="Home">
                            <img v-else src="/assets/icons/intro/home.png" alt="Home">
                        </div>
                        <div class="icon" :class="isActive(1)">
                            <img v-if="index == 1" src="/assets/icons/intro/place-lg.png" alt="Home">
                            <img v-else src="/assets/icons/intro/place.png" alt="Home">
                        </div>
                        <div class="icon icon-logo" :class="isActive(2)">
                            <img v-if="index == 2" src="/assets/icons/intro/logo-lg.png" alt="Home">
                            <img v-else src="/assets/icons/intro/logo.png" alt="Home">
                        </div>
                        <div class="icon" :class="isActive(3)">
                            <img v-if="index == 3" src="/assets/icons/intro/person-lg.png" alt="Home">
                            <img v-else src="/assets/icons/intro/person.png" alt="Home">
                        </div>
                    </div>

                    <div class="navigations">
                        <span v-for="(item, i) in 4" :class="[index == i ? 'active' : '']" :key="i"></span>
                    </div>

                    <button class="btn btn-block" @click="nextSlide">Weiter</button>
                    <p class="skip-intro info" @click="gotToMain">Intro überspringen</p>

                </div>
            </div>
        </div>

        <app-footer/>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from "vueperslides";
    import "vueperslides/dist/vueperslides.css";

    export default {
        name: "Intro",
        components: {
          AppFooter: () => import('@/components/layout/AppFooter'),
          VueperSlides,
          VueperSlide,
      },
      data: () => ({
        activeSlide: 0,
        slideData: [
        'Auf der Startseite findest du Inspirationen und Sehenswertes für deine Reise.',
        'Entdecke Reiseziele auf der Karte.',
        'Stöbere durch Reisepläne der Community oder erstelle deine eigenen Pläne.',
        'Speichere Favoriten und ändere deine Einstellungen in deinem Profil.'
        ]
    }),
      methods: {
        isActive(i){
            if(this.activeSlide == i){
              return "active";
          }
          return "";
      },
      nextSlide(){
        if(this.activeSlide == (this.slideData.length-1)){
            this.gotToMain();
        }
        this.activeSlide = this.activeSlide+1;
    },
    gotToMain(){
        this.$router.push({ name: 'Home' });
    }
}
};
</script>

<style lang="scss">
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .intro {
        height: 100vh;
        margin-bottom: 10vh;

        @media (max-width: 991px) {
          margin-bottom: 0px;
      }

      background-image: url('/assets/bg/onboarding.jpg');

      @include responsive($scroll-breakpoint) {
        padding: 0 20px;
    }

    .wrapper{
        height: 100vh;
        padding-bottom: 0 !important;
        display: flex;
        align-content: center;
        max-width: 450px;
        margin: 0 auto;

        @media (max-width: 500px) {
            max-width:320px;
        }

        @media (max-width: 350px) {
            max-width:280px;
        }

        .inner{
            height: 100vh;
            display: flex;
            align-content: center;
        }

        .intro-slide{
            height: 526px;
            width: 450px;
            vertical-align: middle;
            align-self: center;
            text-align: center;
            background: rgba(255, 255, 255, 0.85);
            padding: 40px 35px;
            border-radius: 10px;
            display: none;

            @media (max-width: 500px) {
                width:320px;
            }

            @media (max-width: 350px) {
                max-width:280px;
            }

            &.active{
                display:block;
            }


            h5 {
                margin-bottom: 1.2rem;
            }

            .logo {
                margin-bottom: 4.0rem;
            }

            .desc {
                color: #1F2041;
                font-size: 14px;
                line-height: 24px;
                height: 80px;
                margin-bottom: 1.2rem;
            }

            .skip-intro{
                cursor: pointer;

                &:hover{
                    color: #E5321B;
                }
            }

            .icons {
                background: $white-color;
                max-width: 280px;
                margin: 0 auto;
                display: flex;
                justify-content: space-around;
                height: 35px;
                max-height: 35px;
                margin-bottom: 3rem;
                box-shadow: 2px 2px 6px 0px #00000026;

                .icon {
                    align-self: center;

                    &.active {
                        position: relative;
                        top: -10px;
                    }

                    &-logo {
                        width: 25px;
                        height: 25px;
                        border-radius: 20px;
                        background-color: #D2D2D2;

                        img {
                            width: 18px;
                        }

                        &.active {
                            position: relative;
                            top: -20px;
                            width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            background-color: $primary;
                            display: inline-flex;
                            box-shadow: 2px 2px 6px #00000026;

                            img {
                                width: 34px;
                                align-self: center;
                                text-align: center;
                                margin: 0 auto;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }

            .navigations {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;

                span {
                    display: block;
                    margin: 0 3px;
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    background: #C4C4C4;

                    &.active {
                        background: #000;
                    }
                }
            }

            .btn {
                margin-bottom: 1.5rem;
            }

            .info {
                color: #000;
                font-weight: 700;
            }

        }
    }

}
</style>
